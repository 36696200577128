import { menu, IMenuItem } from 'configs/menu';

const LeahMenuCollapsed = 'leah-menu-collapsed';
let menuCollapsed: boolean;

export const getMenuCollapsed = () => {
  if (menuCollapsed !== undefined) {
    return menuCollapsed;
  }
  return !!localStorage.getItem(LeahMenuCollapsed);
};

export const saveMenuCollapsed = (collapsed: boolean) => {
  localStorage.setItem(LeahMenuCollapsed, collapsed ? '1' : '');
};

const getMenuMap = () => {
  const map = new Map<string, { current: IMenuItem; path: IMenuItem[] }>();
  const cachedPath: IMenuItem[] = [];

  const deepTraverse = (items: IMenuItem[]) => {
    for (const item of items) {
      const { path, children } = item;
      if (children) {
        cachedPath.push(item);
        deepTraverse(children);
      } else if (path) {
      }
    }
    cachedPath.pop();
  };

  deepTraverse(menu);
  return map;
};

export const mapedMenu = getMenuMap();
