import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

const namespace = 'global';

const checkIsMobile = () =>
  navigator.userAgent
    .toLowerCase()
    .match(/(ipod|iphone|android|coolpad|mmp|smartphone|midp|wap|xoom|symbian|j2me|blackberry|wince)/i) !== null ||
  // 如果body宽度过小，也当做移动端来处理布局
  window.document.body.clientWidth <= 640;

const initialState = {
  loading: false,
  userId: '', // 用户id
  userName: '', // 用户昵称
  avatar: '', // 用户头像
  isRegistered: false, // 是否注册
  isMobile: checkIsMobile(),
};

// 创建带有命名空间的reducer
const globalSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    setUserInfo: (state, action: { payload: { userId?: string; userName?: string; avatar?: string } }) => ({
      ...state,
      userId: action.payload.userId || '',
      userName: action.payload.userName || '',
      avatar: action.payload.avatar || '',
      isRegistered: !!action.payload.userId,
    }),
  },
});

export const selectGlobal = (state: RootState) => state.global;
export const globalActions = globalSlice.actions;
export default globalSlice.reducer;
