import { Button, Carousel, Col, Divider, Image, Row } from 'antd';
import React, { useRef } from 'react';
import './index.less';
import { Link } from 'react-router-dom';
import { PATH_REGISTER } from '../../configs/routerPath';
import { IDENTITY_ROLE } from '../../configs/identity';
import { RightOutlined } from '@ant-design/icons';
import { IMAGE_PREFIX } from '../../configs/global';

// const image2 = `${IMAGE_PREFIX}/1702623489427guide-second.jpg`;
const icon = `${IMAGE_PREFIX}/1702623408023guide-circle.png`;
const image1 = `${IMAGE_PREFIX}/1702623424464guide-first.jpg`;
const carouselImage = `${IMAGE_PREFIX}/1702623424464guide-first.jpg`;

/**
 * 注册引导
 */
const RegisterGuide = () => {
  const carouselRef = useRef(null);
  return (
    <div className='register-guide-container'>
      <Row className='screen-1' gutter={{ sm: 40, lg: 80 }} justify={'space-between'}>
        <Col md={{ span: 9 }} xs={{ span: 24 }} className='left-content'>
          <div className='content-warp'>
            <h2>脑血管畸形患者登记处</h2>
            <p>
              如果您或您的孩子受到脑血管畸形的困扰，您可以通过自愿加入本联盟的方式获取该疾病的相关知识与治疗方法。您的加入将有助于我们找到更好的治疗方法和新的治愈途径。
            </p>
            <p className='liaoyuan'>星星之火可以燎原，您的加入对我们至关重要</p>
            <Link to={`${PATH_REGISTER}?type=${IDENTITY_ROLE.huanzhe}`}>
              <Button type='primary' style={{ marginTop: 43, marginBottom: 43 }}>
                加入病友联盟
                <RightOutlined />
              </Button>
            </Link>
          </div>
        </Col>
        <Col md={{ span: 15 }} xs={{ span: 24 }}>
          <div className='right-content'>
            {/* <div className='right-image'></div> */}
            {/* <Image src={image1} preview={false} /> */}
            <Carousel ref={carouselRef} style={{ width: '100%' }} autoplay={true} dots={false}>
              <Image className='carousel-image' src={carouselImage} preview={false} />
            </Carousel>
            <p>后来，晓薇才知道这是一种焦点感知缺陷的癫痫，症状包括不自主的运动、意识丧失和说话困难</p>
          </div>
        </Col>
      </Row>

      <div className='screen-all'>
        <div>
          <h3>
            <img src={icon} />
            <span>联盟的目标</span>
          </h3>
          <div className='screen-content'>
            <p>1. 找到需要帮助的海绵状血管畸形（CCM）患者</p>
            <p>2. 通过患者自愿登记、定期向医疗机构汇报自身情况、患者的医疗记录收集和生物样本共享等途径进行数据收集</p>
            <p>3. 向研究CCM的科研人员和临床医生提供数据，以回答有关该疾病的病因、治疗方法等相关问题</p>
            <p>4. 成为CCM患者临床试验招募的核心组织</p>
          </div>
        </div>
        <Divider />
      </div>

      <div className='screen-all'>
        <div>
          <h3>
            <img src={icon} />
            <span>为什么加入联盟</span>
          </h3>
          <div className='screen-content'>
            <p>1. 您的加入对CCM的研究至关重要</p>
            <p>2. 我们会确保您的信息安全</p>
            <p>3. 您的数据可以帮助加速我们对该疾病的研究</p>
            <p>4. 您将第一时间了解该疾病的最新研究并有机会参与其中</p>
          </div>
        </div>
        <Divider />
      </div>

      <div className='screen-all'>
        <div>
          <h3>
            <img src={icon} />
            <span>什么是海绵状畸形</span>
          </h3>
          <div className='screen-content'>
            <p>
              海绵状畸形是指大脑或脊髓中壁薄而渗漏的桑葚状异常血管，它也被称为海绵状瘤或海绵状血管瘤。每500人中就有1人患有海绵状畸形，但大多数人都没有意识到，也永远不会出现症状。海绵状畸形可引起出血，引起癫痫发作和类似中风的症状。
            </p>
          </div>
        </div>
        <Divider />
      </div>
    </div>
  );
};

export default RegisterGuide;
