import React from 'react';
import Empty from '../../components/Empty';

/**
 * 团队成员，二期
 */
const AboutMember = () => (
  <div>
    <Empty />
  </div>
);

export default AboutMember;
