import React from 'react';
import { Button, Form, Input, message, Tabs } from 'antd';
import PageBox from '../../components/PageBox';
import './index.less';
import { Link } from 'react-router-dom';
import { PATH_REGISTER } from '../../configs/routerPath';
import CodeCheck from '../../components/CodeCheck';
import { requestLogin } from '../../services/user';
import { SUCCESS_CODE } from '../../configs/constant';
import { setUserToken, sleep } from '../../utils/utils';
import { useAppSelector } from '../../modules/store';
import { selectGlobal } from '../../modules/global';

const Login = () => {
  const globalState = useAppSelector(selectGlobal);
  const [passForm] = Form.useForm();
  const [codeForm] = Form.useForm();

  const getMobile = async () => {
    const mobile = codeForm.getFieldValue('mobile');
    if (!mobile) {
      await codeForm.validateFields(['mobile']);
      return '';
    }
    return mobile;
  };

  const onLogin = async (data: any) => {
    if (data.code === SUCCESS_CODE) {
      message.success('登录成功');
      await sleep(300);
      const { expires, token } = data.data || {};
      setUserToken(token, expires);
      location.href = '/';
    } else {
      message.error(data.msg || '登录失败');
    }
  };

  const passFinish = async () => {
    try {
      const values = await passForm.validateFields();
      const { userName, password } = values;
      const data = await requestLogin(userName, password);
      await onLogin(data);
    } catch (e) {
      console.log(e);
    }
  };
  const codeFinish = async () => {
    try {
      const values = await codeForm.validateFields();
      const { mobile, code } = values;
      const data = await requestLogin(mobile, '', code);
      await onLogin(data);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <PageBox>
      <div className='login-form'>
        <Tabs type='card' defaultActiveKey='password'>
          <Tabs.TabPane tab='密码登录' key='password'>
            <Form size='large' form={passForm} labelCol={{ span: 4 }} wrapperCol={{ span: 16 }} onFinish={passFinish}>
              <Form.Item name='userName' label='用户名' rules={[{ required: true, message: '请输入用户名/手机号' }]}>
                <Input placeholder='请输入用户名/手机号' />
              </Form.Item>
              <Form.Item name='password' label='密码' rules={[{ required: true, message: '请输入登录密码' }]}>
                <Input type='password' placeholder='请输入登录密码' />
              </Form.Item>
              <Form.Item wrapperCol={globalState.isMobile ? undefined : { offset: 4, span: 16 }}>
                <Button block type='primary' htmlType='submit'>
                  登录
                </Button>
                <p className='mt-2'>
                  还没有账号？
                  <Link style={{ color: '@main-prj-color' }} to={PATH_REGISTER}>
                    去注册
                  </Link>
                </p>
              </Form.Item>
            </Form>
          </Tabs.TabPane>
          <Tabs.TabPane tab='验证码登录' key='code'>
            <Form size='large' form={codeForm} labelCol={{ span: 4 }} wrapperCol={{ span: 16 }} onFinish={codeFinish}>
              <Form.Item
                name='mobile'
                label='手机号'
                rules={[
                  { required: true, message: '请输入手机号' },
                  {
                    pattern: /^1\d{10}$/,
                    message: '请输入正确手机号',
                  },
                ]}
              >
                <Input placeholder='请输入手机号' />
              </Form.Item>
              <Form.Item name='code' label='验证码' rules={[{ required: true, message: '请输入验证码' }]}>
                <Input placeholder='请输入验证码' addonAfter={<CodeCheck getMobile={getMobile} />} />
              </Form.Item>
              <Form.Item wrapperCol={globalState.isMobile ? undefined : { offset: 4, span: 16 }}>
                <Button block type='primary' htmlType='submit'>
                  登录
                </Button>
                <p className='mt-2'>
                  还没有账号？
                  <Link style={{ color: '@main-prj-color' }} to={PATH_REGISTER}>
                    去注册
                  </Link>
                </p>
              </Form.Item>
            </Form>
          </Tabs.TabPane>
        </Tabs>
      </div>
    </PageBox>
  );
};

export default Login;
