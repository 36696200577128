import { Button, message } from 'antd';
import React, { useState } from 'react';
import { sendMobileCode } from '../../services/user';
import { SUCCESS_CODE } from '../../configs/constant';

const initCount = 60;

interface ICodeCheck {
  getMobile: () => Promise<string>;
}
const CodeCheck = (props: ICodeCheck) => {
  const [send, setSend] = useState(false);
  const [countdown, setCountdown] = useState(initCount);
  const onClick = async () => {
    const mobile = await props.getMobile();
    if (!mobile) return;
    const data = await sendMobileCode(mobile);
    if (data.code !== SUCCESS_CODE) {
      message.error(data.msg || '发送验证码失败');
      return;
    }
    setSend(true);
    setCountdown(initCount);
    const interval = setInterval(() => {
      setCountdown((count) => {
        const nextCount = count - 1;
        if (nextCount <= 1) {
          clearInterval(interval);
          setSend(false);
          return 0;
        }
        return count - 1;
      });
    }, 1000);
  };
  return (
    <Button type={send ? 'default' : 'primary'} disabled={send} onClick={onClick}>
      {send ? `${countdown}秒后重试` : '获取验证码'}
    </Button>
  );
};

export default CodeCheck;
