import React from 'react';
import Empty from '../../components/Empty';

/**
 * 患者手册下载，二期
 */
const PdfList = () => (
  <div>
    <Empty />
  </div>
);

export default PdfList;
