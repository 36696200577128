import React from 'react';
import { Layout } from 'antd';
import Header from './Header';
import Content from './Content';
import Footer from './Footer';

const Container = React.memo(() => (
  <>
    <Header />
    <Layout>
      <Layout className='leahContentLayout'>
        <Content />
        <Footer />
      </Layout>
    </Layout>
  </>
));
export default Container;
