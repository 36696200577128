export const PATH_MAIN = '/';
export const PATH_LOGIN = '/login';
export const PATH_REGISTER = '/register';
/**
 * 搜索页面
 */
export const PATH_SEARCH = '/search';
/**
 * 联盟简介
 */
export const PATH_REGISTER_GUIDE = '/register/guide';
/**
 * 专家顾问
 */
export const PATH_ABOUT_PROFESSOR = '/about/professor';

/**
 * 团队成员
 */
export const PATH_ABOUT_MEMBER = '/about/member';

/**
 * 推荐医院/医生
 */
export const PATH_REC_LIST = '/article/rec-list';
/**
 * 患者手册下载
 */
export const PATH_PDF_LIST = '/article/pdf-list';

/**
 * 新闻中心
 */
export const PATH_NEWS = '/news';
/**
 * 文章详情
 */
export const PATH_ARTICLE = '/article/:id';

export const PATH_403 = '/403';
export const PATH_404 = '/*';
export const PATH_500 = '/500';

export const getArticlePath = (id: string) => `/article/${id}`;
