interface ILayout {
  theme: TTheme;
}

type TTheme = 'dark' | 'light';

export const layout: ILayout = {
  theme: 'light', // 菜单风格, light, dark
};

export const project = {
  title: '脑血管畸形病友联盟', // 标题
  feedback: '',
  wikiUrl: '',
  leahAuthId: 0,
};

export const IMAGE_PREFIX = 'https://cdn.strokevision.cn/cms/img';
