import { request } from '../utils/request';
import { SUCCESS_CODE } from '../configs/constant';
import { getUserToken } from '../utils/utils';

/**
 * 获取用户信息
 */
export const checkLogin = async () => {
  try {
    const token = getUserToken();
    if (!token) return {};
    const { data } = await request.get(`/apo/cms/userinfo?token=${token}`);
    if (data.code === SUCCESS_CODE && data.data) {
      return data.data;
    }
    return {};
  } catch (e) {
    console.log(e);
  }
  return {};
};

/**
 * 登录
 * @param userName
 * @param password
 */
export const requestLogin = async (userName: string, password?: string, code?: string) => {
  try {
    const { data } = await request.post('/apo/cms/login', {
      username: userName,
      password: password ? password : '',
      code: code ? code : '',
    });
    return data;
  } catch (e) {
    console.log(e);
  }
  return {};
};

/**
 * 登出
 */
export const requestLogout = async () => {
  try {
    const { data } = await request.post('/apo/cms/logout');
    if (data.code) {
      return data;
    }
    return {};
  } catch (e) {
    console.log(e);
  }
  return {};
};

/**
 * 注册
 * @param info
 */
export const requestRegister = async (info: Record<string, string | number>) => {
  try {
    const res = await request.post('/apo/cms/register', info);
    return res.data;
  } catch (e) {
    console.log(e);
  }
  return {};
};

/**
 * 发送验证码
 * @param mobile
 */
export const sendMobileCode = async (mobile: string) => {
  try {
    const { data } = await request.get(`/apo/cms/sendCode?mobile=${mobile}`);
    return data;
  } catch (e) {
    console.log(e);
  }
  return {};
};

/**
 * 获取医院
 * @param keyword
 */
export const getHospitalList = async (keyword: string) => {
  try {
    const { data } = await request.get(`/apo/cms/hospital/list?hospitalName=${keyword}&pageNum=1&pageSize=10`);
    if (data.code === SUCCESS_CODE) {
      return data.rows.map((row: { hospitalName: string; hospitalId: string }) => ({
        label: row.hospitalName,
        value: row.hospitalName,
      }));
    }
    return [];
  } catch (e) {
    console.log(e);
  }
  return [];
};
