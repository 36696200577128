import React, { createRef, useEffect } from 'react';
import './index.less';
import { Carousel, Col, Row, Image, Button, Divider } from 'antd';
import { Link } from 'react-router-dom';
import { getArticlePath, PATH_REGISTER_GUIDE, PATH_REGISTER } from '../../configs/routerPath';
import { CarouselRef } from 'antd/lib/carousel';
import { RightOutlined } from '@ant-design/icons';
import { IMAGE_PREFIX, project } from '../../configs/global';

const screenImage1 = `${IMAGE_PREFIX}/1702449063252home-screen-second-image.jpg`;
const screenImage2 = `${IMAGE_PREFIX}/1702562130848home-screen-first-image.jpeg`;

const cardImage1 = `${IMAGE_PREFIX}/1702630128532home-card-image-1.jpg`;
const cardImage2 = `${IMAGE_PREFIX}/1702630152125home-card-image-2.jpg`;
const cardImage3 = `${IMAGE_PREFIX}/1702630248781home-card-image-3.jpg`;
const cardImage4 = `${IMAGE_PREFIX}/1702632160447home-card-image-4.jpg`;

const shbzImg = `${IMAGE_PREFIX}/1704949749413shbz.png`;
const newsCenterImg1 = `${IMAGE_PREFIX}/1704732327630Rectangle%204099.png`;

const ClockIcon = () => (
  <svg width='12' height='12' viewBox='0 0 12 12' fill='none' style={{ verticalAlign: '-0.125em', marginRight: 4 }}>
    <g clipPath='url(#clip0_1406_6449)'>
      <path
        d='M6 0C2.68661 0 0 2.68661 0 6C0 9.31339 2.68661 12 6 12C9.31339 12 12 9.31339 12 6C12 2.68661 9.31339 0 6 0ZM6 10.9821C3.24911 10.9821 1.01786 8.75089 1.01786 6C1.01786 3.24911 3.24911 1.01786 6 1.01786C8.75089 1.01786 10.9821 3.24911 10.9821 6C10.9821 8.75089 8.75089 10.9821 6 10.9821Z'
        fill='#999999'
        fillOpacity='0.85'
      />
      <path
        d='M8.33984 7.69428L6.43002 6.31348V2.99874C6.43002 2.93982 6.3818 2.8916 6.32287 2.8916H5.67868C5.61975 2.8916 5.57153 2.93982 5.57153 2.99874V6.68714C5.57153 6.72196 5.5876 6.7541 5.61573 6.77419L7.83091 8.38937C7.87912 8.42419 7.94609 8.41348 7.98091 8.3666L8.36394 7.84428C8.39877 7.79473 8.38805 7.72776 8.33984 7.69428Z'
        fill='#999999'
        fillOpacity='0.85'
      />
    </g>
    <defs>
      <clipPath id='clip0_1406_6449'>
        <rect width='12' height='12' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

const Home = () => {
  const carouselRef = createRef<CarouselRef>();

  useEffect(() => {
    const interval = setInterval(() => {
      carouselRef.current?.next();
    }, 10000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className='home-container'>
      <Row className='screen screen-1'>
        <Col md={{ span: 9 }} xs={{ span: 24 }}>
          <div className='left-content'>
            <div className='left-logo'>
              <p className='line-1'>{project.title}</p>
              <p className='line-2'>我们和你在一起</p>
              <p className='line-3'>
                如果您或您的孩子受到脑血管畸形的困扰，您可以通过自愿加入本联盟的方式获取该疾病的相关知识与治疗方法。您的加入将有助于我们找到更好的治疗方法和新的治愈途径。
                <br />
                星星之火可以燎原，您的加入对我们至关重要。
              </p>
              <Link to={PATH_REGISTER}>
                <Button type='primary'>
                  加入病友联盟 <RightOutlined />
                </Button>
              </Link>
            </div>
          </div>
        </Col>
        <Col md={{ span: 15 }} xs={{ span: 24 }}>
          <Carousel ref={carouselRef} style={{ width: '100%' }} autoplay={false} dots={true}>
            <Image src={screenImage1} preview={false} />
            <Image src={screenImage2} preview={false} />
          </Carousel>
        </Col>
      </Row>

      <div className='screen screen-2'>
        <div className='screen-head'>
          <h1>快速了解</h1>
          <h1 style={{ color: '#eee' }}>KNOWLEDGE CENTER</h1>
        </div>
        <Row gutter={24} wrap>
          <Col md={{ span: 6 }} sm={{ span: 12 }} xs={{ span: 12 }}>
            <Link className='item-card' to={getArticlePath('010100')}>
              <div>
                <Image src={cardImage1} preview={false} />
              </div>
              <div className='summary'>
                <h1>什么是海绵状血管畸形？</h1>
                <p>了解并熟悉关于海绵状血管畸形的基础知识</p>
              </div>
            </Link>
          </Col>
          <Col md={{ span: 6 }} sm={{ span: 12 }} xs={{ span: 12 }}>
            <Link className='item-card' to={getArticlePath('010400')}>
              <div>
                <Image src={cardImage2} preview={false} />
              </div>
              <div className='summary'>
                <h1>在我就诊时，应该问医生什么问题？</h1>
                <p>帮助您在就诊和咨询时更好地与医生讨论病情和治疗方案</p>
              </div>
            </Link>
          </Col>
          <Col md={{ span: 6 }} sm={{ span: 12 }} xs={{ span: 12 }}>
            <Link className='item-card' to={getArticlePath('010200')}>
              <div>
                <Image src={cardImage3} preview={false} />
              </div>
              <div className='summary'>
                <h1>我想深入了解海绵状血管畸形</h1>
                <p>20个问题带你快速了解海绵状血管畸形患者在治疗和生活中会遇到的问题</p>
              </div>
            </Link>
          </Col>
          <Col md={{ span: 6 }} sm={{ span: 12 }} xs={{ span: 12 }}>
            <Link className='item-card' to={PATH_REGISTER_GUIDE}>
              <div>
                <Image src={cardImage4} preview={false} />
              </div>
              <div className='summary'>
                <h1>我想加入{project.title}</h1>
                <p>和其他患者、医生、从业者一起互帮互助，推动脑血管畸形的研究和治疗</p>
              </div>
            </Link>
          </Col>
        </Row>
      </div>

      <div className='screen screen-3'>
        <div className='screen-head'>
          <h1>新闻中心</h1>
          <h1 style={{ color: '#eee' }}>NEWS CENTER</h1>
        </div>
        <Row gutter={40} style={{ display: 'none' }}>
          <Col md={{ span: 17 }} xs={{ span: 24 }}>
            <Row gutter={40}>
              <Col md={{ span: 8 }} xs={{ span: 24 }} style={{ marginBottom: 20 }}>
                <Image height={162} wrapperStyle={{ width: '100%' }} src={shbzImg} preview={false} />
              </Col>
              <Col md={{ span: 16 }} xs={{ span: 24 }} className='screen-3_summary' style={{ marginBottom: 20 }}>
                <div style={{ fontSize: 12, fontWeight: 'bold', lineHeight: 1, color: '#B7292B' }}>2024-01-01</div>
                <div style={{ fontSize: 16, lineHeight: 1, fontWeight: 'bold' }}>异地就医怎么使用医保个人账户？</div>
                <div className='text-ellipsis' style={{ fontSize: 14, lineHeight: '21px' }}>
                  自2022年12月12日起，北京市参保人员按规定办理异地就医备案手续后，开通异地就医使用个账支付权限，可在异地备案统筹区开通跨省直接结算相关业务的自2022年12月12日起，北京市参保人员按规定办理异地就医备案手续后，开通异地就医使用个账支付权限，可在异地备案统筹区开通跨省直接结算相关业务的自2022年12月12日起，北京市参保人员按规定办理异地就医备案手续后，开通异地就医使用个账支付权限，可在异地备案统筹区开通跨省直接结算相关业务的
                </div>
                <Button type='primary' ghost>
                  查看详情 <RightOutlined />
                </Button>
              </Col>
            </Row>
            <Divider style={{ margin: '20px 0 40px' }} />
            <Row gutter={30} justify='space-between' className='new-list-wrap'>
              <Col md={{ span: 12 }} xs={{ span: 24 }}>
                <div className='news-list-item'>
                  <p>人社部举办2021年第二季新闻发布会 继续推广养老继续推广养老继续推广养老继续推广养老...</p>
                  <span>
                    <ClockIcon />
                    2021-12-27
                  </span>
                </div>
              </Col>
              <Col md={{ span: 12 }} xs={{ span: 24 }}>
                <div className='news-list-item'>
                  <p>人社部举办2021年第二季新闻发布会 继续推广养老...</p>
                  <span>
                    <ClockIcon />
                    2021-12-27
                  </span>
                </div>
              </Col>
              <Col md={{ span: 12 }} xs={{ span: 24 }}>
                <div className='news-list-item'>
                  <p>人社部举办2021年第二季新闻发布会 继续推广养老...</p>
                  <span>
                    <ClockIcon />
                    2021-12-27
                  </span>
                </div>
              </Col>
              <Col md={{ span: 12 }} xs={{ span: 24 }}>
                <div className='news-list-item'>
                  <p>人社部举办2021年第二季新闻发布会 继续推广养老...</p>
                  <span>
                    <ClockIcon />
                    2021-12-27
                  </span>
                </div>
              </Col>
            </Row>
          </Col>
          <Col md={{ span: 7 }} xs={{ span: 24 }}>
            <div style={{ position: 'relative' }}>
              <Carousel
                className='carousel-self'
                style={{ height: 360, width: 320 }}
                autoplay={true}
                dots={{
                  className: 'news-custom-dots',
                }}
              >
                <Image src={newsCenterImg1} preview={false} />
                <Image src={newsCenterImg1} preview={false} />
              </Carousel>
              <div className='carousel-masker'>
                <Row style={{ flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                  <Col>
                    <div className='masker-head'>就医须知</div>
                  </Col>
                  <Col>
                    <div>
                      <div className='masker-title'>异地就医怎么使用医保个人账户？</div>
                      <div className='masker-summary'>
                        自2022年12月12日起，北京市参保人员按规定办理异地就医备案手续后...
                      </div>
                      <div className='masker-foot'>2022-12-12</div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Home;
