import React from 'react';
import Empty from '../../components/Empty';

/**
 * 推荐医院/医生，二期
 */
const RecList = () => (
  <div>
    <Empty />
  </div>
);

export default RecList;
