import React, { useState } from 'react';
import { Select, SelectProps } from 'antd';
import { getHospitalList } from '../../services/user';

interface IProps {
  placeholder?: string;
  style?: React.CSSProperties;
  value?: string;
  onChange?: (value: string) => void;
}
const SearchInput = (props: IProps) => {
  const [data, setData] = useState<SelectProps<any>['options']>([]);

  const handleSearch = async (newValue: string) => {
    if (newValue) {
      const res = await getHospitalList(newValue);
      setData(res);
    } else {
      setData([]);
    }
  };
  const handleChange = (value: string) => {
    props.onChange?.(value);
  };
  return (
    <Select
      showSearch
      value={props.value}
      placeholder={props.placeholder}
      style={props.style}
      defaultActiveFirstOption={false}
      showArrow={false}
      filterOption={false}
      onSearch={handleSearch}
      onChange={(e) => handleChange(e)}
      notFoundContent={null}
      options={(data || []).map((d) => ({
        value: d.value,
        label: d.label,
      }))}
    />
  );
};

export default SearchInput;
