import * as routerPath from './routerPath';
import { getArticlePath } from './routerPath';

export interface IMenuConfig {
  title: string;
  icon: string;
  id: string;
  path?: string;
  desc?: string;
  children?: IMenuConfig[];
}

// 我是患者，只有一级
export const patientConfig: IMenuConfig[] = [
  {
    title: '疾病简介',
    icon: 'https://react-bootstrap.github.io/img/logo.svg',
    id: '010100',
    path: getArticlePath('010100'),
    desc: '描述描述描述描述描述描述，描述描述描述描述',
  },
  {
    title: '常见问题',
    icon: 'https://react-bootstrap.github.io/img/logo.svg',
    id: '010200',
    path: getArticlePath('010200'),
    desc: '描述描述描述描述描述描述，描述描述描述描述',
  },
  {
    title: '无症状患者',
    icon: 'https://react-bootstrap.github.io/img/logo.svg',
    id: '010300',
    path: getArticlePath('010300'),
    desc: '描述描述描述描述描述描述，描述描述描述描述',
  },
  {
    title: '要问医生的问题',
    icon: 'https://react-bootstrap.github.io/img/logo.svg',
    id: '010400',
    path: getArticlePath('010400'),
    desc: '描述描述描述描述描述描述，描述描述描述描述',
  },
  {
    title: '患者登记',
    icon: 'https://react-bootstrap.github.io/img/logo.svg',
    id: '010500',
    path: routerPath.PATH_REGISTER_GUIDE,
    desc: '描述描述描述描述描述描述，描述描述描述描述',
  },
  {
    title: '推荐医院/医生',
    icon: 'https://react-bootstrap.github.io/img/logo.svg',
    id: '010600',
    path: routerPath.PATH_REC_LIST,
    desc: '描述描述描述描述描述描述，描述描述描述描述',
  },
  {
    title: '患者手册下载',
    icon: 'https://react-bootstrap.github.io/img/logo.svg',
    id: '010700',
    path: routerPath.PATH_PDF_LIST,
    desc: '描述描述描述描述描述描述，描述描述描述描述',
  },
  {
    title: '常见术语',
    icon: 'https://react-bootstrap.github.io/img/logo.svg',
    id: '010800',
    path: getArticlePath('010800'),
    desc: '描述描述描述描述描述描述，描述描述描述描述',
  },
];

// 了解疾病，一级和二级
export const diseaseConfig: IMenuConfig[] = [
  {
    title: '生物学',
    icon: 'https://react-bootstrap.github.io/img/logo.svg',
    id: '020200',
    path: getArticlePath('020200'),
    desc: '描述描述描述描述描述描述，描述描述描述描述',
  },
  {
    title: '影像学',
    icon: 'https://react-bootstrap.github.io/img/logo.svg',
    id: '020300',
    path: getArticlePath('020300'),
    desc: '描述描述描述描述描述描述，描述描述描述描述',
  },
  {
    title: '症状',
    icon: 'https://react-bootstrap.github.io/img/logo.svg',
    id: '020100',
    desc: '描述描述描述描述描述描述，描述描述描述描述',
    children: [
      {
        title: '出血',
        icon: 'https://react-bootstrap.github.io/img/logo.svg',
        id: '020101',
        path: getArticlePath('020101'),
        desc: '描述描述描述描述描述描述，描述描述描述描述',
      },
      {
        title: '癫痫',
        icon: 'https://react-bootstrap.github.io/img/logo.svg',
        id: '020102',
        path: getArticlePath('020102'),
        desc: '描述描述描述描述描述描述，描述描述描述描述',
      },
      {
        title: '头痛',
        icon: 'https://react-bootstrap.github.io/img/logo.svg',
        id: '020103',
        path: getArticlePath('020103'),
        desc: '描述描述描述描述描述描述，描述描述描述描述',
      },
      {
        title: '疲劳',
        icon: 'https://react-bootstrap.github.io/img/logo.svg',
        id: '020104',
        path: getArticlePath('020104'),
        desc: '描述描述描述描述描述描述，描述描述描述描述',
      },
      {
        title: '脑干病变',
        icon: 'https://react-bootstrap.github.io/img/logo.svg',
        id: '020105',
        path: getArticlePath('020105'),
        desc: '描述描述描述描述描述描述，描述描述描述描述',
      },
      {
        title: '脊髓损伤',
        icon: 'https://react-bootstrap.github.io/img/logo.svg',
        id: '020106',
        path: getArticlePath('020106'),
        desc: '描述描述描述描述描述描述，描述描述描述描述',
      },
      {
        title: '发育性静脉异常',
        icon: 'https://react-bootstrap.github.io/img/logo.svg',
        id: '020107',
        path: getArticlePath('020107'),
        desc: '描述描述描述描述描述描述，描述描述描述描述',
      },
      {
        title: '中枢性疼痛综合征',
        icon: 'https://react-bootstrap.github.io/img/logo.svg',
        id: '020108',
        path: getArticlePath('020108'),
        desc: '描述描述描述描述描述描述，描述描述描述描述',
      },
      {
        title: '肥大性橄榄核变性',
        icon: 'https://react-bootstrap.github.io/img/logo.svg',
        id: '020109',
        path: getArticlePath('020109'),
        desc: '描述描述描述描述描述描述，描述描述描述描述',
      },
    ],
  },
  {
    title: '手术治疗',
    icon: 'https://react-bootstrap.github.io/img/logo.svg',
    id: '020400',
    path: getArticlePath('020400'),
    desc: '描述描述描述描述描述描述，描述描述描述描述',
    children: [
      {
        title: '开放性手术',
        icon: 'https://react-bootstrap.github.io/img/logo.svg',
        id: '020401',
        path: getArticlePath('020401'),
        desc: '描述描述描述描述描述描述，描述描述描述描述',
      },
      {
        title: '微创手术',
        icon: 'https://react-bootstrap.github.io/img/logo.svg',
        id: '020402',
        path: getArticlePath('020402'),
        desc: '描述描述描述描述描述描述，描述描述描述描述',
      },
      {
        title: '在研方法',
        icon: 'https://react-bootstrap.github.io/img/logo.svg',
        id: '020403',
        path: getArticlePath('020403'),
        desc: '描述描述描述描述描述描述，描述描述描述描述',
      },
    ],
  },
  {
    title: '日常管理',
    icon: 'https://react-bootstrap.github.io/img/logo.svg',
    id: '020500',
    path: getArticlePath('020500'),
    desc: '描述描述描述描述描述描述，描述描述描述描述',
    children: [
      {
        title: '维生素D',
        icon: 'https://react-bootstrap.github.io/img/logo.svg',
        id: '020501',
        path: getArticlePath('020501'),
        desc: '描述描述描述描述描述描述，描述描述描述描述',
      },
      {
        title: '微生物组/肠道健康',
        icon: 'https://react-bootstrap.github.io/img/logo.svg',
        id: '020502',
        path: getArticlePath('020502'),
        desc: '描述描述描述描述描述描述，描述描述描述描述',
      },
      {
        title: '睡眠/睡眠呼吸暂停',
        icon: 'https://react-bootstrap.github.io/img/logo.svg',
        id: '020503',
        path: getArticlePath('020503'),
        desc: '描述描述描述描述描述描述，描述描述描述描述',
      },
      {
        title: '血液稀释剂',
        icon: 'https://react-bootstrap.github.io/img/logo.svg',
        id: '020504',
        path: getArticlePath('020504'),
        desc: '描述描述描述描述描述描述，描述描述描述描述',
      },
      {
        title: '在研方法',
        icon: 'https://react-bootstrap.github.io/img/logo.svg',
        id: '020505',
        path: getArticlePath('020505'),
        desc: '描述描述描述描述描述描述，描述描述描述描述',
      },
    ],
  },
  {
    title: '孕妇和儿童患者',
    icon: 'https://react-bootstrap.github.io/img/logo.svg',
    id: '020600',
    // path: getArticlePath('020600'),
    desc: '描述描述描述描述描述描述，描述描述描述描述',
    children: [
      {
        title: '孕妇患者',
        icon: 'https://react-bootstrap.github.io/img/logo.svg',
        id: '020601',
        path: getArticlePath('020601'),
        desc: '描述描述描述描述描述描述，描述描述描述描述',
      },
      {
        title: '儿童患者',
        icon: 'https://react-bootstrap.github.io/img/logo.svg',
        id: '020602',
        path: getArticlePath('020602'),
        desc: '描述描述描述描述描述描述，描述描述描述描述',
      },
    ],
  },
];

// 基因检测， 一级和二级
export const geneConfig: IMenuConfig[] = [
  {
    title: '遗传学',
    icon: 'https://react-bootstrap.github.io/img/logo.svg',
    id: '030100',
    path: getArticlePath('030100'),
    desc: '描述描述描述描述描述描述，描述描述描述描述',
  },
  {
    title: '常见突变',
    icon: 'https://react-bootstrap.github.io/img/logo.svg',
    id: '030200',
    path: getArticlePath('030200'),
    desc: '描述描述描述描述描述描述，描述描述描述描述',
    children: [
      {
        title: 'CCM1常见西班牙突变',
        icon: 'https://react-bootstrap.github.io/img/logo.svg',
        id: '030301',
        path: getArticlePath('030301'),
        desc: '描述描述描述描述描述描述，描述描述描述描述',
      },
      {
        title: 'CCM2德系突变',
        icon: 'https://react-bootstrap.github.io/img/logo.svg',
        id: '030302',
        path: getArticlePath('030302'),
        desc: '描述描述描述描述描述描述，描述描述描述描述',
      },
      {
        title: 'CCM2外显子2-10缺失',
        icon: 'https://react-bootstrap.github.io/img/logo.svg',
        id: '030303',
        path: getArticlePath('030303'),
        desc: '描述描述描述描述描述描述，描述描述描述描述',
      },
      {
        title: 'MAP3K3体细胞突变',
        icon: 'https://react-bootstrap.github.io/img/logo.svg',
        id: '030304',
        path: getArticlePath('030304'),
        desc: '描述描述描述描述描述描述，描述描述描述描述',
      },
      {
        title: 'PIK3CA体细胞突变',
        icon: 'https://react-bootstrap.github.io/img/logo.svg',
        id: '030305',
        path: getArticlePath('030305'),
        desc: '描述描述描述描述描述描述，描述描述描述描述',
      },
    ],
  },
  {
    title: '基因检测',
    icon: 'https://react-bootstrap.github.io/img/logo.svg',
    id: '030400',
    desc: '描述描述描述描述描述描述，描述描述描述描述',
    children: [
      {
        title: '我有CCM突变吗？',
        icon: 'https://react-bootstrap.github.io/img/logo.svg',
        id: '030401',
        path: getArticlePath('030401'),
        desc: '描述描述描述描述描述描述，描述描述描述描述',
      },
      {
        title: '为什么要接受基因检测',
        icon: 'https://react-bootstrap.github.io/img/logo.svg',
        id: '030402',
        path: getArticlePath('030402'),
        desc: '描述描述描述描述描述描述，描述描述描述描述',
      },
    ],
  },
  {
    title: 'CCM3综合征',
    icon: 'https://react-bootstrap.github.io/img/logo.svg',
    id: '030300',
    path: getArticlePath('030300'),
    desc: '描述描述描述描述描述描述，描述描述描述描述',
  },
];
// 接受治疗，一级和二级
export const cureConfig: IMenuConfig[] = [
  {
    title: '推荐医院/医生',
    icon: 'https://react-bootstrap.github.io/img/logo.svg',
    id: '040100',
    path: getArticlePath('040100'),
    desc: '描述描述描述描述描述描述',
  },
  {
    title: '异地就诊流程',
    icon: 'https://react-bootstrap.github.io/img/logo.svg',
    id: '040200',
    path: getArticlePath('040200'),
    desc: '描述描述描述描述描述描述',
  },
  {
    title: '医保政策',
    icon: 'https://react-bootstrap.github.io/img/logo.svg',
    id: '040300',
    path: getArticlePath('040300'),
    desc: '描述描述描述描述描述描述',
  },
  {
    title: '准备手术',
    icon: 'https://react-bootstrap.github.io/img/logo.svg',
    id: '040400',
    desc: '描述描述描述描述描述描述',
    children: [
      {
        title: '术前准备',
        icon: 'https://react-bootstrap.github.io/img/logo.svg',
        id: '040401',
        path: getArticlePath('040401'),
        desc: '描述描述描述描述描述描述，描述描述描述描述',
      },
      {
        title: '住院细则',
        icon: 'https://react-bootstrap.github.io/img/logo.svg',
        id: '040402',
        path: getArticlePath('040402'),
        desc: '描述描述描述描述描述描述',
      },
      {
        title: '亲友陪护',
        icon: 'https://react-bootstrap.github.io/img/logo.svg',
        id: '040403',
        path: getArticlePath('040403'),
        desc: '描述描述描述描述描述描述',
      },
    ],
  },
  {
    title: '康复',
    icon: 'https://react-bootstrap.github.io/img/logo.svg',
    id: '040500',
    desc: '描述描述描述描述描述描述',
    children: [
      {
        title: '康复类型',
        icon: 'https://react-bootstrap.github.io/img/logo.svg',
        id: '040501',
        path: getArticlePath('040501'),
        desc: '描述描述描述描述描述描述',
      },
      {
        title: '认知康复',
        icon: 'https://react-bootstrap.github.io/img/logo.svg',
        id: '040502',
        path: getArticlePath('040502'),
        desc: '描述描述描述描述描述描述',
      },
    ],
  },
];

// 临床研究,只有一级
export const studyConfig: IMenuConfig[] = [
  {
    title: '目前研究',
    icon: 'https://react-bootstrap.github.io/img/logo.svg',
    desc: '这是个描述描述这是个描述描述吗，这是个描述描述',
    id: '050100',
    path: getArticlePath('050100'),
  },
  {
    title: '在研疗法',
    icon: 'https://react-bootstrap.github.io/img/logo.svg',
    desc: '这是个描述描述这是个描述描述吗，这是个描述描述',
    id: '050200',
    path: getArticlePath('050200'),
  },
  {
    title: '世界各地的研究',
    icon: 'https://react-bootstrap.github.io/img/logo.svg',
    desc: '这是个描述描述这是个描述描述吗，这是个描述描述',
    id: '050300',
    path: getArticlePath('050300'),
  },
];

// const list = [patientConfig, cureConfig, diseaseConfig, studyConfig, geneConfig];
// const result: { id: string; title: string }[] = [];
// list.forEach((item) => {
//   item.forEach((subItem) => {
//     subItem.path?.startsWith('/article') &&
//       result.push({
//         id: subItem.id,
//         title: subItem.title,
//       });
//     if (subItem.children?.length) {
//       subItem.children.map((sub) => {
//         sub.path?.startsWith('/article') &&
//           result.push({
//             id: sub.id,
//             title: sub.title,
//           });
//       });
//     }
//   });
// });
//
// console.log(JSON.stringify(result));
