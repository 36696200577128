import React from 'react';
import { cureConfig, diseaseConfig, geneConfig, IMenuConfig, patientConfig, studyConfig } from './headerMenu';

export interface IMenuItem {
  key: string;
  label: string;
  path?: string;
  children?: IMenuItem[];
  icon?: React.FC;
  list: IMenuConfig[];
}

export const menu: IMenuItem[] = [
  {
    key: 'patient',
    label: '我是患者',
    list: patientConfig,
  },
  {
    key: 'disease',
    label: '了解疾病',
    list: diseaseConfig,
  },
  {
    key: 'gene',
    label: '基因检测',
    list: geneConfig,
  },
  {
    key: 'cure',
    label: '接受治疗',
    list: cureConfig,
  },
  {
    key: 'study',
    label: '临床研究',
    list: studyConfig,
  },
];
