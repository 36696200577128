import React from 'react';
import { BrowserRouterProps } from 'react-router-dom';
import Home from 'pages/Home';
import * as routerPath from './routerPath';
import Login from '../pages/Login';
import Register from '../pages/Register';
import Search from '../pages/Search';
import RecList from '../pages/RecList';
import PdfList from '../pages/PdfList';
import NewsList from '../pages/NewsList';
import RegisterGuide from '../pages/RegisterGuide';
import AboutProfessor from '../pages/AboutProfessor';
import AboutMember from '../pages/AboutMember';
import ArticleDetail from '../pages/ArticleDetail';

interface IRouteItem {
  key?: string; // 无key时，没有权限校验
  path: string;
  exact?: boolean;
  Component: React.FC<BrowserRouterProps>;
}

export const routes: IRouteItem[] = [
  {
    key: routerPath.PATH_LOGIN,
    path: routerPath.PATH_LOGIN,
    Component: Login,
  },
  {
    key: routerPath.PATH_REGISTER_GUIDE,
    path: routerPath.PATH_REGISTER_GUIDE,
    Component: RegisterGuide,
  },
  {
    key: routerPath.PATH_REGISTER,
    path: routerPath.PATH_REGISTER,
    Component: Register,
  },
  {
    key: routerPath.PATH_SEARCH,
    path: routerPath.PATH_SEARCH,
    Component: Search,
  },
  {
    key: routerPath.PATH_REC_LIST,
    path: routerPath.PATH_REC_LIST,
    Component: RecList,
  },
  {
    key: routerPath.PATH_PDF_LIST,
    path: routerPath.PATH_PDF_LIST,
    Component: PdfList,
  },
  {
    key: routerPath.PATH_NEWS,
    path: routerPath.PATH_NEWS,
    Component: NewsList,
  },
  {
    key: routerPath.PATH_ABOUT_PROFESSOR,
    path: routerPath.PATH_ABOUT_PROFESSOR,
    Component: AboutProfessor,
  },
  {
    key: routerPath.PATH_ABOUT_MEMBER,
    path: routerPath.PATH_ABOUT_MEMBER,
    Component: AboutMember,
  },
  {
    key: routerPath.PATH_ARTICLE,
    path: routerPath.PATH_ARTICLE,
    Component: ArticleDetail,
  },
  {
    key: routerPath.PATH_MAIN,
    path: routerPath.PATH_MAIN,
    Component: Home,
  },
];
