import React, { memo, useEffect, useMemo } from 'react';
import { Layout, LayoutProps, Dropdown, Menu, Space, Button, Input } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { project } from 'configs/global';
import LMenu from './Menu';
import { useAppDispatch, useAppSelector } from '../modules/store';
import { checkLogin, requestLogout } from '../services/user';
import { globalActions, selectGlobal } from '../modules/global';
import { PATH_SEARCH } from '../configs/routerPath';
import classNames from 'classnames';

const { Header } = Layout;
const { Search } = Input;

const User = memo(() => {
  const globalState = useAppSelector(selectGlobal);
  const dispatch = useAppDispatch();

  useEffect(() => {
    checkLogin().then((res) => {
      dispatch(
        globalActions.setUserInfo({
          userId: res.userId,
          userName: res.username,
          avatar: res.avatar,
        }),
      );
    });
  }, []);

  const logout = async () => {
    await requestLogout();
    location.href = '/';
  };

  const menu = useMemo(
    () => (
      <Menu>
        <Menu.Item key='logout' onClick={logout}>
          <Space size='small'>
            <LogoutOutlined />
            <span>退出登录</span>
          </Space>
        </Menu.Item>
      </Menu>
    ),
    [],
  );

  if (!globalState.isRegistered) {
    return (
      <Link to='/login'>
        <Button type='primary'>登录注册</Button>
      </Link>
    );
  }

  return (
    <Dropdown overlay={menu}>
      <Space size='small' align='center'>
        {/* <Avatar src={globalState.avatar} className='leahAvatar' />*/}
        <span>{globalState.userName}</span>
      </Space>
    </Dropdown>
  );
});

export const Logo = memo(() => (
  <Link to='/'>
    <div className='leahLogo'></div>
  </Link>
));

export const TextLogo = memo(() => (
  <Link to='/'>
    <div className='leahTextLogo'>{project.title}</div>
  </Link>
));

interface ILinkButtonProps {
  text: string;
  href?: string;
}

export const LinkButton = (props: ILinkButtonProps) => (
  <a target='_blank' href={project.feedback} rel='noreferrer' className='linkButton'>
    {props.text}
  </a>
);

const LHeader = ({ className, children, ...otherProps }: React.PropsWithChildren<LayoutProps>) => {
  const globalState = useAppSelector(selectGlobal);
  const history = useHistory();

  const isHomePage = useRouteMatch({
    path: '/',
    exact: true,
    strict: true,
    sensitive: true,
  });

  const onSearch = (value: string) => {
    history.push(`${PATH_SEARCH}?keyword=${value}`);
  };

  return (
    <Header {...otherProps} style={{ padding: 0 }}>
      <div
        className={classNames({
          leahHeader: true,
          'leahHeader-bg': isHomePage,
        })}
      >
        <div className='leahTitle'>{!globalState.isMobile && <TextLogo />}</div>

        <div className='leahMenu'>
          <LMenu />
          <Space size={20}>
            {/* {project.feedback && <LinkButton text='用户反馈' href={project.feedback} />}*/}
            {/* {project.wikiUrl && <LinkButton text='帮助手册' href={project.wikiUrl} />}*/}
            <Search onSearch={onSearch} placeholder='请输入搜索' className='headerSearch' />
            <User />
          </Space>
        </div>
      </div>
    </Header>
  );
};

export default memo(LHeader);
