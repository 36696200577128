import React, { useState } from 'react';
import './index.less';
import {
  Button,
  Card,
  Cascader,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Radio,
  Result,
  Row,
  Select,
  Space,
  Steps,
} from 'antd';
import { Steps as MSteps } from 'antd-mobile';
import { IDENTITY_LIST, IDENTITY_ROLE, IDENTITY_ROLE_DESC, TITLE_OPTION } from '../../configs/identity';
import { useHistory } from 'react-router-dom';
import { PATH_LOGIN } from '../../configs/routerPath';
import CodeCheck from '../../components/CodeCheck';
import moment, { Moment } from 'moment';
import CITY_CONFIG from '../../configs/city';
import { requestRegister } from '../../services/user';
import SearchInput from '../../components/SearchInput';
import { SUCCESS_CODE } from '../../configs/constant';
import queryString, { ParsedQuery } from 'query-string';
import { locale } from '../../configs/locale';
import classNames from 'classnames';
import { useAppSelector } from '../../modules/store';
import { selectGlobal } from '../../modules/global';

const { Step } = Steps;
const { Step: MStep } = MSteps;

const initValues = {
  // username: 'draven',
  // name: '德莱问',
  // password: 'qwertyuiop',
  // password2: 'qwertyuiop',
  // mobile: '15563985432',
  // code: '1234',
  // gender: '男',
  // province: ['北京市', '北京市', '海淀区'],
  // relate: '知音',
  // org: '篮球俱乐部',
  // title: '部长',
  birthday: moment('1990-01-01'),
};

const RegisterForm = (props: { role: string; onFinish: () => void; onBack: () => void }) => {
  const { onBack, role } = props;
  const [form] = Form.useForm();

  const onFinish = async () => {
    try {
      const values = await form.validateFields();
      const { province: provinceValue, password, password2 } = values;
      if (password !== password2) {
        message.error('两次输入的密码不一致');
        return;
      }
      const [province, city, county] = provinceValue;
      const registerParams = {
        identity: role,
        username: values.username,
        password,
        mobile: values.mobile,
        code: values.code,
        name: values.name,
        gender: values.gender,
        birthday: moment.isMoment(values.birthday) ? (values.birthday as Moment).format('YYYY-MM-DD') : '',
        province,
        city,
        county,
        relate: values.relate || '',
        org: values.org || '',
        title: values.title || '',
      };
      const res = await requestRegister(registerParams);
      if (res.code === SUCCESS_CODE) {
        props.onFinish();
      } else {
        message.error(res.msg);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getMobile = async () => {
    const mobile = form.getFieldValue('mobile');
    if (!mobile) {
      await form.validateFields(['mobile']);
      return '';
    }
    return mobile;
  };

  return (
    <Form form={form} labelCol={{ span: 4 }} wrapperCol={{ span: 16 }} onFinish={onFinish} initialValues={initValues}>
      <Form.Item label=' ' colon={false}>
        您选择的身份是: {IDENTITY_ROLE_DESC[role as keyof typeof IDENTITY_ROLE_DESC]}
      </Form.Item>
      <Form.Item name='username' label='用户名' rules={[{ required: true, message: '请输入用户名' }]}>
        <Input placeholder='请输入用户名' />
      </Form.Item>
      <Form.Item name='password' label='密码' rules={[{ required: true, message: '请输入密码' }]}>
        <Input type='password' placeholder='请输入密码' />
      </Form.Item>
      <Form.Item name='password2' label='确认密码' rules={[{ required: true, message: '请输入确认密码' }]}>
        <Input type='password' placeholder='请输入确认密码' />
      </Form.Item>
      <Form.Item
        name='mobile'
        label='手机号'
        rules={[
          { required: true, message: '请输入手机号' },
          {
            pattern: /^1\d{10}$/,
            message: '请输入正确手机号',
          },
        ]}
      >
        <Input placeholder='请输入手机号' />
      </Form.Item>
      <Form.Item name='code' label='验证码' rules={[{ required: true, message: '请输入验证码' }]}>
        <Input placeholder='请输入验证码' addonAfter={<CodeCheck getMobile={getMobile} />} />
      </Form.Item>

      <Form.Item name='name' label='姓名' rules={[{ required: true, message: '请输入姓名' }]}>
        <Input placeholder='请输入姓名' />
      </Form.Item>

      <Form.Item name='gender' label='性别' rules={[{ required: true, message: '请选择性别' }]}>
        <Select placeholder='请选择性别'>
          <Select.Option value='男'>男</Select.Option>
          <Select.Option value='女'>女</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item label='出生日期' name='birthday' rules={[{ required: true, message: '请选择出生日期' }]}>
        {/* @ts-ignore */}
        <DatePicker locale={locale as any} />
      </Form.Item>

      {role !== IDENTITY_ROLE.doctor && (
        <Form.Item name='province' label='居住城市' rules={[{ required: true, message: '请选择居住城市' }]}>
          <Cascader options={CITY_CONFIG} placeholder='请选择居住城市' fieldNames={{ value: 'label' }} />
        </Form.Item>
      )}

      {role === IDENTITY_ROLE.doctor && (
        <Form.Item name='province' label='工作城市' rules={[{ required: true, message: '请选择工作城市' }]}>
          <Cascader options={CITY_CONFIG} placeholder='请选择工作城市' fieldNames={{ value: 'label' }} />
        </Form.Item>
      )}

      {role === IDENTITY_ROLE.huanzhefirends && (
        <Form.Item name='relate' label='患者是您的' rules={[{ required: true, message: '请输入与患者关系' }]}>
          <Input placeholder='请输入与患者关系' />
        </Form.Item>
      )}

      {role === IDENTITY_ROLE.doctor && (
        <Form.Item name='org' label='就职医院' rules={[{ required: true, message: '请输入就职医院' }]}>
          <SearchInput placeholder='请输入就职医院' />
        </Form.Item>
      )}
      {role === IDENTITY_ROLE.doctor && (
        <Form.Item name='title' label='职称' rules={[{ required: true, message: '请选择职称' }]}>
          <Select placeholder='请选择职称'>
            {TITLE_OPTION.map((item) => (
              <Select.Option key={item} value={item}>
                {item}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}

      {role === IDENTITY_ROLE.other && (
        <Form.Item name='org' label='就职单位' rules={[{ required: true, message: '请输入就职单位' }]}>
          <Input placeholder='请输入就职单位' />
        </Form.Item>
      )}
      {role === IDENTITY_ROLE.other && (
        <Form.Item name='title' label='职务' rules={[{ required: true, message: '请输入职务' }]}>
          <Input placeholder='请输入职务' />
        </Form.Item>
      )}

      <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
        <Space>
          <Button type='primary' onClick={onBack}>
            上一步
          </Button>
          <Button type='primary' htmlType='submit'>
            提交注册信息
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

const Register = () => {
  const parsed: ParsedQuery<any> = queryString.parse(location.search);
  const globalState = useAppSelector(selectGlobal);
  const { type = '' } = parsed;
  const history = useHistory();
  const initRole = IDENTITY_ROLE[type as keyof typeof IDENTITY_ROLE] ? type : '';
  const [role, setRole] = useState(initRole);
  const [step, setStep] = useState(initRole ? 1 : 0);

  const gotoRegister = () => {
    if (!role) return;
    setStep(1);
  };

  const gotoLogin = () => {
    history.push(PATH_LOGIN);
  };

  return (
    <div className='register-container'>
      <div className='step-container'>
        {globalState.isMobile ? (
          <MSteps current={step}>
            <MStep title='身份选择' status='process' />
            <MStep title='填写注册信息' status={step > 0 ? 'process' : 'wait'} />
            <MStep title='完成注册' status={step > 1 ? 'process' : 'wait'} />
          </MSteps>
        ) : (
          <Steps current={step} type='navigation' size='small'>
            <Step title='身份选择' status='process' />
            <Step title='填写注册信息' status={step > 0 ? 'process' : 'wait'} />
            <Step title='完成注册' status={step > 1 ? 'process' : 'wait'} />
          </Steps>
        )}
      </div>
      {step === 0 && (
        <div className='identity-container'>
          <Radio.Group
            name='radiogroup'
            defaultValue={initRole}
            value={role}
            style={{ width: '100%' }}
            onChange={(e) => setRole(e.target.value)}
          >
            <Row gutter={5} style={{ width: '100%' }} justify={'space-between'}>
              {IDENTITY_LIST.map((item) => (
                <Col key={item.type} onClick={() => setRole(item.type)} md={{ span: 4 }} xs={{ span: 24 }}>
                  {/* <Card title={item.title}>
                    <Button type='primary' block onClick={() => gotoRegister(item.type)}>
                      点击去注册
                    </Button>
                  </Card> */}
                  <div
                    className={classNames({
                      'role-item': true,
                      'role-item-selected': item.type === role,
                    })}
                    style={{
                      backgroundImage: `url('${item.image}')`,
                    }}
                  >
                    <Radio value={item.type}>
                      <span style={{ fontSize: 16, fontWeight: 'bold' }}>{item.title}</span>
                    </Radio>
                  </div>
                </Col>
              ))}
            </Row>
          </Radio.Group>
          <Row style={{ marginTop: 100, width: '100%' }} justify={'center'} gutter={30}>
            <Col>
              <Button danger style={{ width: 88 }} onClick={() => setRole('')}>
                取消
              </Button>
            </Col>
            <Col>
              <Button type='primary' style={{ width: 88 }} onClick={() => gotoRegister()}>
                确定
              </Button>
            </Col>
          </Row>
        </div>
      )}
      {step === 1 && (
        <div className='register-form'>
          <RegisterForm role={role} onFinish={() => setStep(2)} onBack={() => setStep(0)} />
        </div>
      )}
      {step === 2 && (
        <div className='register-finish'>
          <Result
            status='success'
            title='您已经完成注册'
            extra={[
              <Button type='primary' key='console' onClick={() => gotoLogin()}>
                去登录
              </Button>,
            ]}
          />
        </div>
      )}
    </div>
  );
};

export default Register;
