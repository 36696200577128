import React from 'react';
import Empty from '../../components/Empty';

/**
 * 专家顾问，二期
 */
const AboutProfessor = () => (
  <div>
    <Empty />
  </div>
);

export default AboutProfessor;
