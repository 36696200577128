import React from 'react';
import classnames from 'classnames';

import Style from './index.module.less';

interface IErrorPageProps {
  code: '403' | '404' | '500' | '200';
  showCode?: boolean;
  msg?: string;
}

const ErrorPage = (props: IErrorPageProps) => {
  const { code, showCode = true, msg } = props;
  let codeStyle;

  switch (code) {
    case '403':
      codeStyle = Style.error403;
      break;
    case '404':
      codeStyle = Style.error404;
      break;
    case '500':
      codeStyle = Style.error500;
      break;
    case '200':
      codeStyle = Style.errorEmpty;
      break;
  }

  return (
    <div className={Style.errorBox}>
      <div className={classnames(Style.errorImage, codeStyle)}></div>
      {showCode && <div className={Style.errorCode}>{props.code}</div>}
      <div className={Style.errorMsg}>{msg ? msg : '发生错误'}</div>
    </div>
  );
};

export default React.memo(ErrorPage);
