import React, { useEffect } from 'react';
import { Layout, Spin } from 'antd';
import { layout, project } from 'configs/global';
import { useAppSelector } from 'modules/store';
import Container from './Container';
import './layout.less';
import { useHistory } from 'react-router-dom';
import { PATH_MAIN } from '../configs/routerPath';

const setTheme = (theme: string) => {
  document.documentElement.setAttribute('theme', theme);
};

const resolveClass = (isAdd: boolean) => {
  const header = document.querySelector('#root .ant-layout-header');
  if (header) {
    const origin = header.className.split(' ');
    const index = origin.indexOf('leahHeaderContainer');
    if (index === -1 && isAdd) {
      origin.push('leahHeaderContainer');
    } else if (index > -1 && !isAdd) {
      origin.splice(index, 1);
    }
    header.className = origin.join(' ');
  }
};

const LLayout = () => {
  const history = useHistory();
  const globalState = useAppSelector((state) => state.global);

  useEffect(() => {
    document.title = project.title;
    setTheme(layout.theme);
    if (history.location.pathname === PATH_MAIN) {
      resolveClass(true);
    }
    history.listen((event) => {
      if (event.pathname === PATH_MAIN) {
        resolveClass(true);
      } else {
        resolveClass(false);
      }
    });
    return () => {
      resolveClass(false);
    };
  }, []);

  return (
    <Layout className='leahLayout'>{globalState.loading ? <Spin className='pageLoading' /> : <Container />}</Layout>
  );
};

export default React.memo(LLayout);
