import { SUCCESS_CODE, USER_LOGIN_KEY } from '../configs/constant';

export const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const setUserToken = (token: string, expires: number) => {
  localStorage.setItem(
    USER_LOGIN_KEY,
    JSON.stringify({
      token,
      expires: Date.now() + expires * 1000,
    }),
  );
};

export const getUserToken = (): string => {
  try {
    const value = localStorage.getItem(USER_LOGIN_KEY);
    if (!value) return '';
    const { token, expires } = JSON.parse(value);
    if (token && expires && expires > Date.now()) {
      return token;
    }
  } catch (e) {}
  return '';
};

export const utilFormatResult = (data: any) => {
  if (data.code === SUCCESS_CODE) {
    return {
      list: data.rows || [],
      total: data.total || 0,
    };
  }
  return {
    list: [],
    total: 0,
  };
};
