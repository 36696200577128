import { request } from '../utils/request';
import { PAGE_SIZE, SUCCESS_CODE } from '../configs/constant';
import { utilFormatResult } from '../utils/utils';

/**
 * 获取文章
 */
export const getArticleInfo = async (artId: string) => {
  try {
    const { data } = await request.get(`/apo/cms/art?code=${artId}`);
    if (data.code === SUCCESS_CODE) {
      return data.data;
    }
  } catch (e) {
    console.log(e);
  }
  return {};
};

/**
 * 获取文章
 */
export const searchArticle = async (keyword: string, page: number) => {
  try {
    const { data } = await request.get(`/apo/cms/art/search?keywords=${keyword}&page=${page}&pageSize=${PAGE_SIZE}`);
    return utilFormatResult(data);
  } catch (e) {
    console.log(e);
  }
  return {
    list: [],
    total: 0,
  };
};
