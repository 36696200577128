import { useHistory, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Col, Image, Row, Skeleton, Button } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { getArticleInfo } from '../../services/article';
import Empty from '../../components/Empty';
import { PATH_REGISTER } from '../../configs/routerPath';

import './index.less';
import {IMAGE_PREFIX} from "../../configs/global";

const defaultBanner = `${IMAGE_PREFIX}/1705391606798banner.jpg`;
const registerImg = `${IMAGE_PREFIX}/1704818497014icon_record.png`;

const ArticleDetail = () => {
  const params: any = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    artId: 0,
    banner: '',
    title: '',
    brief: '',
    content: '',
    showRegister: false,
  });
  useEffect(() => {
    getArticleInfo(params.id).then((res) => {
      setLoading(false);
      setData({
        artId: res.artId,
        banner: res.banner || defaultBanner,
        title: res.artTitle,
        brief: res.artBrief,
        content: res.artHtml,
        showRegister: res.isOriginal === 'Y',
      });
    });
    return () => {
      setLoading(true);
    };
  }, [params.id]);

  if (loading) {
    return <Skeleton active />;
  }

  if (!data.artId) {
    return <Empty />;
  }

  return (
    <div className='article-detail'>
      <div
        className='article-banner'
        style={{
          backgroundImage: `url('${data.banner}')`,
        }}
      >
        {data.title && <h1>{data.title}</h1>}
        {data.brief && <h3>{data.brief}</h3>}
      </div>
      <div className='article-content'>
        {data.showRegister && (
          <div className='register-entry'>
            <Row gutter={10} wrap={false} className='register-entry-content'>
              <Col span={10}>
                <Image
                  src={registerImg}
                  preview={false}
                />
              </Col>
              <Col span={14}>
                <h3>加入患者登记</h3>
                <p>互帮互助，推动脑血管畸形的研究和治疗</p>
                <Button type='primary' onClick={() => history.push(PATH_REGISTER)}>
                  加入病友联盟 <RightOutlined />
                </Button>
              </Col>
            </Row>
          </div>
        )}
        <div dangerouslySetInnerHTML={{ __html: data.content }}></div>
      </div>
    </div>
  );
};

export default ArticleDetail;
