import React, { useEffect, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';
import { Col, Dropdown, Menu, Row, Space } from 'antd';
import Icon, { MenuUnfoldOutlined } from '@ant-design/icons';
import { layout } from 'configs/global';
import { IMenuItem, menu } from 'configs/menu';
import { mapedMenu } from 'utils/layout';
import { IMenuConfig } from '../configs/headerMenu';
import { useAppSelector } from '../modules/store';
import { selectGlobal } from '../modules/global';
import classNames from 'classnames';

const { theme } = layout;
const { Item } = Menu;

const MenuOverlay = (props: { list: IMenuConfig[]; onRedirect?: () => void }) => {
  const history = useHistory();
  const gotoArticle = (article?: string) => {
    if (!article) return;
    history.push(article);
    props.onRedirect?.();
  };

  const { list } = props;
  return (
    <div className='menuOverlay'>
      {list.map((item) => {
        const hasChild = Array.isArray(item.children) && item.children.length > 0;
        return (
          <div key={item.id} className='overlayItem'>
            <div className={`${item.path ? 'pointer' : ''}`} onClick={() => gotoArticle(item.path)}>
              <strong>{item.title}</strong>
            </div>
            {hasChild ? (
              <div className='overlaySubList'>
                {item.children?.map((subItem) => (
                  <div key={subItem.id} className={`overlaySubItem pointer`} onClick={() => gotoArticle(subItem.path)}>
                    {subItem.title}
                  </div>
                ))}
              </div>
            ) : null}
          </div>
        );
        // if (hasChild) {
        //   return (
        //     <div key={item.id}>
        //       <div className={`overlayBlockItem ${item.path ? 'pointer' : ''}`} onClick={() => gotoArticle(item.path)}>
        //         <div>
        //           <strong>{item.title}</strong>
        //         </div>
        //         <div className='overlaySubList'>
        //           {item.children?.map((subItem) => (
        //             <div
        //               key={subItem.id}
        //               className={`overlaySubItem pointer`}
        //               onClick={() => gotoArticle(subItem.path)}
        //             >
        //               {subItem.title}
        //             </div>
        //           ))}
        //         </div>
        //       </div>
        //     </div>
        //   );
        // }
        // return (
        //   <div className={`overlayItem pointer`} key={item.id} onClick={() => gotoArticle(item.path)}>
        //     <div>
        //       <strong>{item.title}</strong>
        //     </div>
        //   </div>
        // );
      })}
    </div>
  );
};

const MobileMenu = (props: { list: IMenuItem[] }) => {
  const { list } = props;
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const [selectedMenuKey, setSelectedMenuKey] = useState<string>('');
  const [selectedSubMenuKey, setSelectedSubMenuKey] = useState<string>('');
  const [selectedSubMenu, setSelectedSubMenu] = useState<IMenuConfig[]>([]);

  const handleMenuLeve1Click = (menuItem: IMenuItem) => {
    const { key, list: subMenu } = menuItem;
    setSelectedMenuKey(key);
    setSelectedSubMenu(subMenu);
  };

  const handleSubMenuClick = (subMenu: IMenuConfig) => {
    const { path, id } = subMenu;
    path && history.push(path);
    setVisible(false);
    setSelectedSubMenuKey(id);
  };

  return (
    <div className='mobileMenu'>
      <div>
        <MenuUnfoldOutlined onClick={() => setVisible((show) => !show)} />
      </div>
      {visible && (
        <div>
          <div className='mobileMask' onClick={() => setVisible(false)} />
          <div className='mobileContaner'>
            {/* {list.map((item) => {
              const { key, label, list } = item;
              return (
                <div key={key}>
                  <h3>{label}</h3>
                  <MenuOverlay list={list} onRedirect={() => setVisible(false)} />
                </div>
              );
            })} */}
            <Row align={'top'} wrap={false}>
              <Col style={{ background: '#F9F9F9', minWidth: 90, width: 90 }}>
                <Space direction='vertical' size={0} style={{ display: 'flex', padding: '0 12px' }}>
                  {list.map((item) => {
                    const { key, label, list } = item;
                    return (
                      <div
                        key={key}
                        onClick={() => handleMenuLeve1Click(item)}
                        className={classNames({
                          'menu-item': true,
                          'menu-item-selected': selectedMenuKey === key,
                        })}
                      >
                        {label}
                      </div>
                    );
                  })}
                </Space>
              </Col>
              {selectedSubMenu.length > 0 ? (
                <Col flex={1} className='sub-menu-ctn'>
                  <Row gutter={10}>
                    {selectedSubMenu.map((item) => {
                      const { id, children = [], title } = item;
                      return (
                        <Col span={8} key={id} style={{ marginBottom: 10 }}>
                          <Space direction='vertical' size={10}>
                            <div
                              className={classNames({
                                'sub-menu-title': true,
                                'sub-menu-selected': selectedSubMenuKey === id,
                              })}
                              onClick={() => handleSubMenuClick(item)}
                            >
                              {title}
                            </div>
                            {children.map((subItem) => {
                              const { id, title } = subItem;
                              return (
                                <div
                                  className={classNames({
                                    'sub-menu-item': true,
                                    'sub-menu-selected': selectedSubMenuKey === id,
                                  })}
                                  key={id}
                                  onClick={() => handleSubMenuClick(subItem)}
                                >
                                  {title}
                                </div>
                              );
                            })}
                          </Space>
                        </Col>
                      );
                    })}
                  </Row>
                </Col>
              ) : null}
            </Row>
          </div>
        </div>
      )}
    </div>
  );
};

const getMenuItem = (menu: IMenuItem[]) =>
  menu.map((item) => {
    const { key, list, icon, label, path } = item;
    const IC = icon ? <Icon component={icon} /> : null;

    if (!list || list.length === 0) {
      return (
        <Item key={key} icon={IC} style={{ padding: '0 16px' }}>
          <Link to={path as string}>{label}</Link>
        </Item>
      );
    }
    return (
      <Dropdown trigger={['hover', 'click']} key={key} placement='bottomCenter' overlay={<MenuOverlay list={list} />}>
        <Item eventKey={key} icon={IC} style={{ padding: '0 16px' }}>
          {label}
        </Item>
      </Dropdown>
    );
  });

const LMenu = () => {
  const globalState = useAppSelector(selectGlobal);
  const { pathname } = useLocation();
  const selectedMap = mapedMenu.get(pathname);
  const [openKeys, setOpenKeys] = useState<string[]>([]);

  const selectedKeys = useMemo(() => {
    if (selectedMap) {
      return [selectedMap.current.key];
    }
    return [];
  }, [pathname]);

  if (globalState.isMobile) {
    return <MobileMenu list={menu} />;
  }

  return (
    <Menu
      style={{ borderBottom: 'none', fontSize: 14 }}
      className='lMenu'
      mode='horizontal'
      onOpenChange={(openKeys) => setOpenKeys(openKeys as string[])}
      openKeys={openKeys}
      selectedKeys={selectedKeys}
      theme={theme}
    >
      {getMenuItem(menu)}
    </Menu>
  );
};

export default React.memo(LMenu);
