import Empty from '../../components/Empty';
import React from 'react';

/**
 * 新闻中心，二期
 */
const NewsList = () => (
  <div>
    <Empty />
  </div>
);

export default NewsList;
