import React, { useEffect, useState } from 'react';
import { Empty, Input, Divider, Pagination, Spin } from 'antd';
import './index.less';
import queryString, { ParsedQuery } from 'query-string';
import { searchArticle } from '../../services/article';
import { Link } from 'react-router-dom';
import { getArticlePath } from '../../configs/routerPath';
import { PAGE_SIZE } from '../../configs/constant';

const { Search } = Input;

interface IArtData {
  artId: number;
  code: string;
  artTitle: string;
  artBrief: string;
}

const SearchPage = () => {
  const parsed: ParsedQuery<any> = queryString.parse(location.search);
  const { keyword = '' } = parsed;

  const [searchValue, setSearchValue] = useState(keyword);
  const [listData, setListData] = useState({
    page: 1,
    list: [] as IArtData[],
    total: 0,
    searchStatus: false,
    loading: false,
  });

  const fetcher = async (keyword: string, nextPager: number) => {
    if (!keyword) {
      return;
    }
    setListData((listData) => ({
      page: nextPager,
      total: listData.total,
      list: [],
      loading: true,
      searchStatus: true,
    }));
    const { list, total } = await searchArticle(keyword, nextPager);
    setListData({
      page: nextPager,
      list,
      total,
      searchStatus: true,
      loading: false,
    });
  };

  const onSearch = async (value: string, event: any) => {
    console.log(event.type);
    setSearchValue(value);
    await fetcher(value, 1);
  };
  const onChange = (value: string) => {
    setSearchValue(value);
  };

  const onChangePage = async (page: number) => {
    await fetcher(searchValue, page);
  };

  useEffect(() => {
    if (keyword) {
      fetcher(keyword, listData.page);
    }
  }, []);

  return (
    <div className='search-container'>
      <Search
        placeholder='输入关键词进行搜索'
        allowClear
        enterButton='搜索'
        size='large'
        value={searchValue}
        onSearch={onSearch}
        onChange={(e) => onChange(e.target.value)}
      />
      {listData.searchStatus && (
        <div className='search-list'>
          {listData.loading && <Spin spinning={true} />}
          {listData.list.length === 0 && !listData.loading && <Empty />}
          {listData.list.map((item) => {
            const id = item.artId;
            return (
              <Link key={id} className='art-item' to={getArticlePath(item.code)}>
                <h1>{item.artTitle}</h1>
                <p>{item.artBrief}</p>
                <Divider />
              </Link>
            );
          })}

          {listData.total > PAGE_SIZE && (
            <Pagination
              showSizeChanger={false}
              current={listData.page}
              total={listData.total}
              pageSize={PAGE_SIZE}
              onChange={onChangePage}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default SearchPage;
